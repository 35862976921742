<script setup>
  import { ref } from 'vue';

  // Function to check if the icon is an image (i.e., URL)
  const isImage = icon => {
    return (
      icon &&
      (icon.endsWith('.png') || icon.endsWith('.jpg') || icon.endsWith('.jpeg'))
    );
  };
  const props = defineProps({
    featureTitle: {
      type: String,
      required: false
    },
    featureSubtitle: {
      type: String,
      required: false
    },
    featureDescription: {
      type: String,
      required: false
    },
    featurePoint1Title: {
      type: String,
      required: false
    },
    featurePoint1Description: {
      type: String,
      required: false
    },
    featurePoint1Icon: {
      type: String,
      required: false,
      default: 'fa-face-laugh-wink'
    },
    featurePoint2Title: {
      type: String,
      required: false
    },
    featurePoint2Description: {
      type: String,
      required: false
    },
    featurePoint2Icon: {
      type: String,
      required: false
    },
    featurePoint3Title: {
      type: String,
      required: false
    },
    featurePoint3Description: {
      type: String,
      required: false
    },
    featurePoint3Icon: {
      type: String,
      required: false,
      default: 'fa-user'
    },
    featureImage1: {
      type: String,
      required: false
    },
    featurePoint4Title: {
      type: String,
      required: false
    },
    featurePoint4Description: {
      type: String,
      required: false
    },
    featurePoint4Icon: {
      type: String,
      required: false
    },
    featurePoint5Title: {
      type: String,
      required: false
    },
    featurePoint5Description: {
      type: String,
      required: false
    },
    featurePoint5Icon: {
      type: String,
      required: false
    },
    featurePoint6Title: {
      type: String,
      required: false
    },
    featurePoint6Description: {
      type: String,
      required: false
    },
    featurePoint6Icon: {
      type: String,
      required: false
    }
  });
</script>

<template>
  <div>
    <div class="flex flex-col text-center font-title text-3xl">
      <p v-html="featureTitle"></p>
      <p class="font-semibold" v-html="featureSubtitle"></p>
      <p class="mb-4 mt-4 text-sm">
        {{ featureDescription }}
      </p>
    </div>
  </div>
  <div class="mx-auto flex w-5/6 max-w-5xl flex-col md:flex-row">
    <!-- Left Section -->
    <div class="pl-8 md:w-1/2">
      <!-- <div>
        <div class="flex flex-col font-title text-3xl">
          <p v-html="featureTitle"></p>
          <p class="font-semibold" v-html="featureSubtitle"></p>
        </div>
        <p class="mt-4 text-sm">
          {{ featureDescription }}
        </p>
      </div> -->

      <!-- Feature Points -->
      <div class="mt-6">
        <div class="flex flex-col gap-8">
          <!-- Feature Point 1 -->
          <div class="flex flex-row items-center gap-3">
            <div
              class="flex aspect-square h-12 w-12 items-center justify-center rounded-full bg-purple-500"
            >
              <template v-if="isImage(featurePoint1Icon)">
                <img
                  :src="featurePoint1Icon"
                  alt="Feature Point 1 Icon"
                  class="h-6 w-6 object-contain"
                />
              </template>
              <template v-else>
                <i
                  :class="`fa-solid ${featurePoint1Icon} text-2xl text-white`"
                ></i>
              </template>
            </div>
            <div>
              <p class="font-title font-semibold">
                {{ featurePoint1Title }}
              </p>
              <p class="text-sm">
                {{ featurePoint1Description }}
              </p>
            </div>
          </div>

          <!-- Feature Point 2 -->
          <div class="flex flex-row items-center gap-3">
            <div
              class="flex aspect-square h-12 w-12 items-center justify-center rounded-full bg-purple-500"
            >
              <template v-if="isImage(featurePoint1Icon)">
                <img
                  :src="featurePoint2Icon"
                  alt="Feature Point 2 Icon"
                  class="h-6 w-6 object-contain"
                />
              </template>
              <template v-else>
                <i
                  :class="`fa-solid ${featurePoint2Icon} text-2xl text-white`"
                ></i>
              </template>
            </div>
            <div>
              <p class="font-title font-semibold">
                {{ featurePoint2Title }}
              </p>
              <p class="text-sm">
                {{ featurePoint2Description }}
              </p>
            </div>
          </div>

          <!-- Feature Point 3 -->
          <div class="flex flex-row items-center gap-3">
            <div
              class="flex aspect-square h-12 w-12 items-center justify-center rounded-full bg-purple-500"
            >
              <template v-if="isImage(featurePoint2Icon)">
                <img
                  :src="featurePoint3Icon"
                  alt="Feature Point 1 Icon"
                  class="h-6 w-6 object-contain"
                />
              </template>
              <template v-else>
                <i
                  :class="`fa-solid ${featurePoint3Icon} text-2xl text-white`"
                ></i>
              </template>
            </div>
            <div>
              <p class="font-title font-semibold">
                {{ featurePoint3Title }}
              </p>
              <p class="text-sm">
                {{ featurePoint3Description }}
              </p>
            </div>
          </div>

          <!-- 4 -->
          <div
            class="flex flex-row items-center gap-3"
            v-if="featurePoint4Title"
          >
            <div
              class="flex aspect-square h-12 w-12 items-center justify-center rounded-full bg-purple-500"
            >
              <template v-if="isImage(featurePoint2Icon)">
                <img
                  :src="featurePoint3Icon"
                  alt="Feature Point 1 Icon"
                  class="h-6 w-6 object-contain"
                />
              </template>
              <template v-else>
                <i
                  :class="`fa-solid ${featurePoint4Icon} text-2xl text-white`"
                ></i>
              </template>
            </div>
            <div>
              <p class="font-title font-semibold">
                {{ featurePoint4Title }}
              </p>
              <p class="text-sm">
                {{ featurePoint4Description }}
              </p>
            </div>
          </div>

          <!-- 5 -->
          <div
            class="flex flex-row items-center gap-3"
            v-if="featurePoint5Title"
          >
            <div
              class="flex aspect-square h-12 w-12 items-center justify-center rounded-full bg-purple-500"
            >
              <template v-if="isImage(featurePoint2Icon)">
                <img
                  :src="featurePoint3Icon"
                  alt="Feature Point 1 Icon"
                  class="h-6 w-6 object-contain"
                />
              </template>
              <template v-else>
                <i
                  :class="`fa-solid ${featurePoint5Icon} text-2xl text-white`"
                ></i>
              </template>
            </div>
            <div>
              <p class="font-title font-semibold">
                {{ featurePoint5Title }}
              </p>
              <p class="text-sm">
                {{ featurePoint3Description }}
              </p>
            </div>
          </div>

          <!-- six -->
          <div
            class="flex flex-row items-center gap-3"
            v-if="featurePoint6Title"
          >
            <div
              class="flex aspect-square h-12 w-12 items-center justify-center rounded-full bg-purple-500"
            >
              <template v-if="isImage(featurePoint6Icon)">
                <img
                  :src="featurePoint3Icon"
                  alt="Feature Point 1 Icon"
                  class="h-6 w-6 object-contain"
                />
              </template>
              <template v-else>
                <i
                  :class="`fa-solid ${featurePoint6Icon} text-2xl text-white`"
                ></i>
              </template>
            </div>
            <div>
              <p class="font-title font-semibold">
                {{ featurePoint6Title }}
              </p>
              <p class="text-sm" v-html="featurePoint6Description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Section -->
    <div
      class="flex hidden flex-row items-center justify-center md:block md:w-1/2"
    >
      <div class="mx-auto ml-20 flex h-full items-center justify-center">
        <svg
          width="283"
          height="326"
          viewBox="0 0 283 326"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <!-- Define the custom mirrored shape -->
          <!-- <defs>
              <clipPath id="custom-reversed-shape">
                <path
                  d="M71.292 325.262L125.948 320.733C237.792 311.293 310.952 199.24 274.586 93.0762C271.434 83.8746 264.666 76.3557 255.844 72.2556L116.194 7.3501C70.9998 -13.6553 18.75 17.0304 15.096 66.725L1.253 255.005C-1.708 295.279 32.0436 328.659 71.292 325.262Z"
                />
              </clipPath>
            </defs> -->

          <image
            :href="featureImage1"
            width="283"
            height="326"
            clip-path="url(#custom-mirrored-shape)"
            preserveAspectRatio="xMidYMid slice"
            alt="Vector Image"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
