<script setup>
import { ref } from 'vue';
import mainHero2 from '../../../public/images/welcome/mainHero2.jpg';

import MainButton from '@/Components/MainButton.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import { Link } from '@inertiajs/vue3';

const isLoading = ref(false);

const handleImageLoad = () => {
    isLoading.value = false;
};
</script>

<template>
    <div>
        <!-- Hero Image -->
        <div class="relative h-[580px] md:h-[700px] lg:h-[800px]">
            <!-- <div v-if="isLoading" class="loader"></div> -->
            <img :src="mainHero2" alt="Hero Image"
                class="absolute inset-0 object-cover w-full h-full overflow-hidden shadow-xl rounded-b-2xl bg-blend-darken"
                @load="handleImageLoad" />
            <div class="hero-image-overlay"></div>

            <!-- Text Section -->
            <section class="absolute z-20 text-white left-4 top-20 lg:left-20 lg:top-36">
                <div class="max-w-6xl">
                    <div class="flex flex-col text-3xl font-title md:text-4xl lg:gap-4 lg:text-6xl">
                        <p>Your Platform for</p>
                        <p class="font-semibold">Effortless Gig</p>
                        <p class="font-semibold">Management</p>
                    </div>
                    <div class="mt-4">
                        <p class="w-1/2 text-sm md:text-lg">
                            Spicy Gigs gives creators a safe, streamlined space to find and
                            manage gigs, collaborate, and stay organized—all for a simple
                            <span class="font-semibold">$20/month</span>
                            .
                        </p>
                    </div>
                    <div class="flex flex-row gap-4 mt-4">
                        <MainButton>
                            <a href="/waitlist" class="!text-white hover:!text-white">
                                Join Spicygigs
                            </a>
                        </MainButton>
                        <SecondaryButton class="text-white border-white">
                            <a href="#collaborate" class="!text-white">Explore More</a>
                        </SecondaryButton>
                    </div>
                </div>
            </section>
        </div>

        <!-- Bottom Content -->
        <div class="bottom-content -mt-36 h-44 bg-primary"></div>
    </div>
</template>

<style scoped>
.bottom-content {
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}

.hero-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 1rem;
    z-index: 10;
    /* Ensure overlay is below the text section */
}

.loader {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top: 8px solid #6508d5;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
